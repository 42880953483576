import { customerOrdersAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { spacesToHyphens } from '@backmarket/utils/string/spacesToHyphens'
import { type Step } from '@ds/components/StatusTracker'

const parseDate = (value: string | null): string | undefined => {
  if (typeof value === 'string') {
    const i18n = useI18n()

    return i18n.date(new Date(value), {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
  }

  return undefined
}

type orderlineStatusTimelineStep = Step & {
  details?: string
  date?: string
}

export interface orderlineStatusTimeline {
  currentStep: string
  steps: orderlineStatusTimelineStep[]
}

export const getOrderlineStatusTimeline = (
  statusTimeline: customerOrdersAPI.OrderlineTimeline[],
): orderlineStatusTimeline => {
  let currentStep = ''

  const steps = statusTimeline.map((step) => {
    if (step.current) {
      currentStep = spacesToHyphens(step.type)
    }

    return {
      current: step.current,
      hint: step.hint ? step.hint : undefined,
      label: step.label,
      key: spacesToHyphens(step.type),
      date: parseDate(step.date),
      details: step.details ? step.details : undefined,
    }
  })

  return {
    currentStep,
    steps,
  }
}
