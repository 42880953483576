<template>
  <div class="contents" data-test="orderline-timeline">
    <ClientOnly>
      <template #fallback>
        <RevSkeleton
          v-for="index in 3"
          :key="index"
          class="mb-2"
          height="1.2rem"
          shape="rectangle"
          width="100%"
        />
        <RevSkeleton
          class="mb-2"
          height="1.2rem"
          shape="rectangle"
          width="25%"
        />
      </template>

      <RevStatusTracker
        :current-step="orderlineStatusTimeline.currentStep"
        :data-qa="orderlineStatusTimeline.currentStep"
        :orientation="orientation"
        :steps="orderlineStatusTimeline.steps"
      >
        <template
          v-for="step in orderlineStatusTimeline.steps"
          :key="step.key"
          #[`${step.key}-date`]
        >
          {{ step.date }}
        </template>
        <template
          v-for="step in orderlineStatusTimeline.steps"
          :key="step.key"
          #[`${step.key}-details`]
        >
          {{ step.details }}
        </template>
      </RevStatusTracker>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { customerOrdersAPI } from '@backmarket/http-api'
import { useBreakpoint } from '@backmarket/utils/composables/useBreakpoint'
import { Breakpoint } from '@backmarket/utils/dom/getCurrentBreakpoint'
import { RevSkeleton } from '@ds/components/Skeleton'
import {
  type Orientations,
  RevStatusTracker,
} from '@ds/components/StatusTracker'

import { getOrderlineStatusTimeline } from './helpers'

const props = defineProps<{
  timeline: customerOrdersAPI.GetOrderlineTimelineResponse
}>()

const breakpoint = useBreakpoint()

const orderlineStatusTimeline = computed(() =>
  getOrderlineStatusTimeline(props.timeline),
)

const orientation = computed<Orientations>(() =>
  breakpoint.value < Breakpoint.LG ? 'vertical' : 'horizontal',
)
</script>
